/*
 * @Descripttion: 产品基础资源池
 * @Author: renmingming
 * @Date: 2022-04-21 14:17:06
 * @LastEditors: renmingming
 * @LastEditTime: 2022-04-24 09:57:34
 */

import http from '@/utils/http';
import { ListType, ResourceListType, ProductType } from '@/interface/productResourcePool';

/**
 * 产品基础资源池列表
 * @param params
 */
export function getResourceList(params) {
  return http.get<ResourceListType>(`/passport-resources/1/SysResourcesProcess`, params);
}

/**
 * 资源启用
 * @param sequenceNbr
 */
export function resourceActive(sequenceNbr: string) {
  return http.put(`/sys-resource/1/sysResources/active/${sequenceNbr}`);
}

/**
 * 资源停用
 * @param sequenceNbr
 */
export function resourceStop(sequenceNbr: string) {
  return http.put(`/sys-resource/1/sysResources/stop/${sequenceNbr}`);
}
/**
 * 资源删除
 * @param sequenceNbr
 */
export function resourceDelete(sequenceNbr: string) {
  return http.delete(`/sys-resource/1/sysResources/${sequenceNbr}`);
}

//  获取资源来源
export function resourcesRegionGet(): any {
  return http.get(`/sys-resource/1/sysResourcesSource/list`);
}
//  获取父级资源
export function parentSequenceGet(formdata: any): any {
  return http.get(`/sys-resource/1/sysResources/list`, formdata);
}
//  新建表单提交
export function sysResourcesSubmit(params: any): any {
  return http.post(`sys-resource/1/sysResources`, params);
}
/**
 * 根据code查询资源来源
 * @param codes
 */
export function batchQueryByCodes(codes: string[]) {
  return http.post<Record<string, any>[]>(
    'sys-resource/1/sysResourcesSource/batchQueryByCodes',
    codes
  );
}

/**
 * 资源引用查询
 * @param sequenceNbr
 */
export function resourceCiteBy(sequenceNbr: string) {
  return http.get<ListType[]>(
    `/sys-resource/1/sysResources/citedBy/${sequenceNbr}`
  );
}

/**
 * 产品列表
 * @param ids
 */
export function batchProductByIds(ids: any) {
  return http.post<ProductType[]>(
    `/product-resource/1/application/batchQueryByIds`,
    ids
  );
}


/**
 * 版本列表
 */
export function getVersionList() {
  return http.get<string[]>('/sys-resource/1/sysResources/versionList')
}