




























import Vue from 'vue';
import { resourceCiteBy, batchProductByIds } from '@/api/productResourcePool';
import { ListType, ProductType } from '@/interface/productResourcePool';
const productListColumns = [
  {
    title: '序号',
    dataIndex: 'index',
    align: 'center',
  },
  {
    title: '产品名称',
    dataIndex: 'name',
    align: 'center',
    scopedSlots: { customRender: 'name' },
  },
];
export default Vue.extend({
  name: 'productList',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      productListColumns: Object.freeze(productListColumns),
      productNameMap: {},
      productListData: [] as ListType[],
    };
  },
  methods: {
    cancel() {
      this.$emit('update:visible', false);
    },
    getListData(sequenceNbr: string) {
      resourceCiteBy(sequenceNbr).then((res) => {
        const { status, result } = res;
        if (status !== 200) return '';
        this.productListData = result;
        const values = result.map((item) => item.value);
        batchProductByIds(values).then((res) => {
          const { status, result } = res;
          if (status !== 200) return '';
          let nameMap = {};
          for (let item of result) {
            nameMap[item.clientId] = item.applicationName;
          }
          this.productNameMap = nameMap;
        });
      });
    },
  },
});
